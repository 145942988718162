/* width */
.product_scroll::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  .product_scroll::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  .product_scroll::-webkit-scrollbar-thumb {
    background: #888; 
  }
 