@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  behavior: smo;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul.main-menu{}
ul.main-menu > li {float: left;padding: 15px 20px;background: rgb(255, 255, 255);position: relative;}

li > ul.child-menu{width: 250px;position: absolute;border:1px solid #f2f2f2; border-radius:25%;z-index: 5555;border: 1px solid #f2f2f2;border-radius: 5px; 
  opacity: 0;visibility: hidden;transition: ease-out .35s;-moz-transition: ease-out .35s;-webkit-transition: ease-out .35s;}
ul.child-menu li{width: 100%;background-color: #fff;}
li.child:hover ul.child-menu{opacity: 1;visibility: visible;left: -250px;}
li.child:hover ul.child-menu li.child:hover ul.child-menu{opacity: 1;visibility: visible;left: -5px;top:0px;position: absolute;}
ul.child-menu li a {display: block;width: 100%;padding: 15px 20px;}
ul.child-menu li a svg{float: right;rotate: -90deg;}
li.child {position: relative;}

.collapsible {
  display: none;
  background-color: white;
  color: blue;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: center;
  font-size: 15px;font-weight: 600;
}
.product-description p {margin: 10px 0;}
.video-wrapper {
  text-align: center;
  position: relative;
  overflow: hidden;
  max-width: 100%;
  padding-bottom: 56.25%;
  height: 0;
  height: auto;
}
.space-y-9 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: 8px;
  margin-bottom: 0px;
}

/* carousal */

.carousel {
  position: relative;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.64);
  margin-top: 26px;
}

.carousel-inner {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.carousel-open:checked + .carousel-item {
  position: static;
  opacity: 100;
}

.carousel-item {
  position: absolute;
  opacity: 0;
  -webkit-transition: opacity 0.6s ease-out;
  transition: opacity 0.6s ease-out;
}

.carousel-item img {
  display: block;
  height: auto;
  max-width: 100%;
}

.carousel-control {
  background: rgba(0, 0, 0, 0.28);
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  display: none;
  font-size: 40px;
  height: 40px;
  line-height: 35px;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  cursor: pointer;
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  text-align: center;
  width: 40px;
  z-index: 10;
}

.carousel-control.prev {
  left: 2%;
}

.carousel-control.next {
  right: 2%;
}

.carousel-control:hover {
  background: rgba(0, 0, 0, 0.8);
  color: #aaaaaa;
}

#carousel-1:checked ~ .control-1,
#carousel-2:checked ~ .control-2,
#carousel-3:checked ~ .control-3 {
  display: block;
}

.carousel-indicators {
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  bottom: 2%;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 10;
}

.carousel-indicators li {
  display: inline-block;
  margin: 0 5px;
}

.carousel-bullet {
  color: #fff;
  cursor: pointer;
  display: block;
  font-size: 35px;
}

.carousel-bullet:hover {
  color: #aaaaaa;
}

#carousel-1:checked ~ .control-1 ~ .carousel-indicators li:nth-child(1) .carousel-bullet,
#carousel-2:checked ~ .control-2 ~ .carousel-indicators li:nth-child(2) .carousel-bullet,
#carousel-3:checked ~ .control-3 ~ .carousel-indicators li:nth-child(3) .carousel-bullet {
  color: #428bca;
}

#title {
  width: 100%;
  position: absolute;
  padding: 0px;
  margin: 0px auto;
  text-align: center;
  font-size: 27px;
  color: rgba(255, 255, 255, 1);
  font-family: 'Open Sans', sans-serif;
  z-index: 9999;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.33), -1px 0px 2px rgba(255, 255, 255, 0);
}

